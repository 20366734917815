<template>
  <div v-if="group">
    <b-nav class="mt-2" content-class="mt-2" justified tabs>
      <b-nav-item
        :to="`/groups/${group.id}/details`"
        exact
        exact-active-class="active"
      >
        <span class="d-none d-md-inline">Détails du groupe</span>
      </b-nav-item>
      <b-nav-item
        v-if="group.has_coach_transportation"
        :to="`/groups/${group.id}/details/coach-transportation`"
        exact
        exact-active-class="active"
      >
        <span class="d-none d-md-inline">Transport par autocar/navette</span>
      </b-nav-item>
      <b-nav-item
        v-if="group.has_flight_transportation"
        :to="`/groups/${group.id}/details/flight-transportation`"
        exact
        exact-active-class="active"
      >
        <span class="d-none d-md-inline">Transport aérien</span>
      </b-nav-item>
      <b-nav-item
        v-if="group.has_accommodation"
        :to="`/groups/${group.id}/details/accommodation`"
        exact
        exact-active-class="active"
      >
        <span class="d-none d-md-inline">Hébergement</span>
      </b-nav-item>
    </b-nav>

    <router-view />
  </div>
</template>

<script>
import { BRow, BCol, BNav, BNavItem } from "bootstrap-vue";

export default {
  components: { BRow, BCol, BNav, BNavItem },
  computed: {
    group() {
      return this.$store.state["app-groups"]?.currentGroup;
    },
    transportations() {
      return this.$store.state["app-groups"]?.transportations;
    },
    accommodations() {
      return this.$store.state["app-groups"]?.accommodations;
    },
  },
};
</script>
